export const CONFIG = {
    // business_username: "mknxbranches",
    // business_username: "jalapenoshack",
    business_username: "mknx",
     business_id: "76",
    //  business_id: "431",
    //  business_id: "153",
     splash_text:'SandBox',
     code_check:'0001',
    //  splash_image:'assets/animation/loader.svg',
    splash_image:''


   //    appId: "",
   //     googleProjectId: "",
   //      marketPlace: false,
   //       company: 'mknx',
   //        env: 'production' 
   }
  